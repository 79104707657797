import { useEffect, useRef, useState } from "react";
import Field, { FieldProps } from "./Field";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange, Range, RangeKeyDict } from "react-date-range";
import moment from "moment";
import { BaseProps } from "./Element";
import { tr } from "date-fns/locale";

type DateRangeSelectorProps = FieldProps & {
  seperator: "&" | "to";
  rangeSelected?: (range: Range) => void;
  ranges?: Range[];
  readonly?: boolean;
};

export function DateRangeSelector(props: DateRangeSelectorProps) {
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<Range>(
    props.ranges && props.ranges.length > 0
      ? props.ranges[0]
      : {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        }
  );
  const [value, setValue] = useState<string>(
    props.ranges && props.ranges.length > 0
      ? `${moment(props.ranges[0].startDate).format("DD-MM-YYYY")} ${
          props.seperator
        } ${moment(props.ranges[0].endDate).format("DD-MM-YYYY")}`
      : ""
  );
  const refCalendar = useRef<HTMLDivElement>(null);

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };
  const hideOnClickOutside = (e: MouseEvent) => {
    if (
      refCalendar.current &&
      !refCalendar.current.contains(e.target as Node)
    ) {
      setOpen(false);
    }
  };

  const applySelectedRange = () => {
    setValue(
      `${moment(range.startDate).format("DD-MM-YYYY")} ${
        props.seperator
      } ${moment(range.endDate).format("DD-MM-YYYY")}`
    );
    if (props.rangeSelected) {
      props.rangeSelected(range);
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (props.ranges && props.ranges.length > 0) {
      setRange(props.ranges[0]);
      setValue(
        `${moment(props.ranges[0].startDate).format("DD-MM-YYYY")} ${
          props.seperator
        } ${moment(props.ranges[0].endDate).format("DD-MM-YYYY")}`
      );
    }
  }, [props.ranges, props.seperator]);

  return (
    <div>
      <Field
        onClick={() => setOpen(true)}
        title={props.title}
        readOnly={true}
        autoComplete="off"
        value={value}
      >
        <span
          className="position-absolute end-0 top-50 mt-1 me-3"
          style={{
            cursor: "pointer",
            transform: "translateY(-50%)",
          }}
          onClick={() => setOpen(true)}
        >
          <i className="fa fa-calendar fs-4"></i>
        </span>
      </Field>
      <div
        ref={refCalendar}
        className="position-absolute d-flex flex-column"
        style={{
          zIndex: 1000,
          border: "1px solid #ccc",
          display: open ? "block" : "none",
        }}
      >
        {open && !props.readOnly && (
          <>
            <DateRange
              onChange={(rangesByKey: RangeKeyDict) => {
                setRange(rangesByKey.selection);
              }}
              ranges={[range]}
              weekStartsOn={1}
              months={2}
              direction="horizontal"
              locale={tr}
            />
            <button
              className="btn btn-danger"
              onClick={() => {
                setRange({
                  startDate: moment().toDate(),
                  endDate: moment().toDate(),
                  key: "selection",
                });
                setValue("");
              }}
            >
              Seçimi Temizle
            </button>
            <button
              className="btn btn-primary"
              onClick={(e) => {
                e.stopPropagation();
                applySelectedRange();
              }}
            >
              Tamam
            </button>
          </>
        )}
      </div>
      {props.children}
    </div>
  );
}

type DateRangeInputProps = BaseProps & {
  name: string;
  seperator: "&" | "to";
  onChange: (event: { target: { name: string; value: string } }) => void;
  ranges?: Range[];
  defaultValue?: string;
  readonly?: boolean;
};

export function DateRangeInput(props: DateRangeInputProps) {
  const [range, setRange] = useState<Range>(() => {
    if (props.defaultValue) {
      const [start, end] = props.defaultValue.split(props.seperator);
      return {
        startDate: moment(start.trim(), "YYYY-MM-DD").toDate(),
        endDate: moment(end.trim(), "YYYY-MM-DD").toDate(),
        key: "selection",
      };
    } else {
      return {
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        key: "selection",
      };
    }
  });

  useEffect(() => {
    if (props.defaultValue) {
      const [start, end] = props.defaultValue.split(props.seperator);
      setRange({
        startDate: moment(start.trim(), "YYYY-MM-DD").toDate(),
        endDate: moment(end.trim(), "YYYY-MM-DD").toDate(),
        key: "selection",
      });
    }
  }, [props.defaultValue, props.seperator]);

  return (
    <DateRangeSelector
      readOnly={props.readonly}
      title={props.title}
      seperator={props.seperator}
      ranges={[range]}
      rangeSelected={(range: Range) => {
        setRange(range);
        if (props.onChange) {
          const event = {
            target: {
              name: props.name,
              value: `${moment(range.startDate).format("YYYY-MM-DD")} ${
                props.seperator
              } ${moment(range.endDate).format("YYYY-MM-DD")}`,
            },
          };
          props.onChange(event);
        }
      }}
    />
  );
}
