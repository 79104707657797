import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};

class Customer {
  name?: string;
  surname?: string;
  password?: string;
  email?: string;
  phone?: string;
  national_id?: string;
  city?: string;
  country?: string;
  lang?: string;
}

class CustomersEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Customer() };
  }

  getApiUrl(): string {
    return "/customers/save/" + this.props.itemIdForUpdate;
  }

  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/customers/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      customers: this.state.entity,
    };
  }

  render(): ReactNode {
    var state = this.state as any;
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide(); // Close the modal
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Müşteri Ekle"
                : "Müşteri Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Field
                title="İsim"
                className="form-control"
                type="text"
                name="name"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.name}
                required={true}
              />
              <Field
                title="Soyisim"
                className="form-control"
                type="text"
                name="surname"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.surname}
                required={true}
              />
              <Field
                title="Şifre"
                className="form-control"
                type="password"
                name="password"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.password}
                required={true}
              />
              <Field
                title="Mail Adresi"
                className="form-control"
                type="email"
                name="email"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.email}
                required={true}
              />
              <Field
                title="Telefon Numarası"
                className="form-control"
                type="tel"
                name="phone"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.phone}
              />
              <Field
                title="Kimlik Numarası"
                className="form-control"
                type="text"
                name="national_id"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.national_id}
              />
              <Field
                title="Şehir"
                className="form-control"
                type="text"
                name="city"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.city}
              />
              <Field
                title="Ülke"
                className="form-control"
                type="text"
                name="country"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.country}
              />
              <Field
                title="Dil Tercihi"
                className="form-control"
                type="text"
                name="lang"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.lang}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                    this.props.onHide(); // Close the modal
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { CustomersEditModal };
