import { ResultProps } from "../../../utils/ResultViewer/ResultViewer";
import Table from "../../../utils/ResultViewer/Table";
type PropertiesProps = ResultProps & {
  resInfoCallback: Function;
};
class ReservationsTable extends Table {
  //@ts-ignore
  public state: PropertiesProps;
  constructor(props: PropertiesProps) {
    super(props);
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: "",
      orderDirection: "",
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      resInfoCallback: props.resInfoCallback,
    };
  }
  renderBadge = (status: any) => {
    switch (status) {
      case "Onaylandı":
        return <span className="badge badge-light-success">Onaylandı</span>;
      case "Beklemede":
        return <span className="badge badge-light-warning">Beklemede</span>;
      case "Müşteri İptal Etti":
        return (
          <span className="badge badge-light-danger">Müşteri İptal Etti</span>
        );
      case "Vilbox İptal Etti":
        return (
          <span className="badge badge-light-danger">Vilbox İptal Etti</span>
        );
      case "Giriş Yaptı":
        return <span className="badge badge-light-secondary">Giriş Yaptı</span>;
      case "Çıkış Yaptı":
        return <span className="badge badge-light-secondary">Çıkış Yaptı</span>;
      default:
        return null; // veya başka bir varsayılan durum
    }
  };

  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === "edit_actions") {
      return (
        <td key={colIndex}>
          <div className="d-flex justify-content-end flex-shrink-0">
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fa fa-edit"></span>
            </button>

            <button
              onClick={() =>
                this.state.resInfoCallback(row[key], this.fetchData)
              }
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fas fa-share-alt"></span>
            </button>
            <button
              onClick={() =>
                this.props.deleteCallback(row[key], this.fetchData)
              }
              className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
            >
              <span className="fa fa-trash"></span>
            </button>
          </div>
        </td>
      );
    } else if (key == "reservation_status") {
      console.log("value: ", row[key]);
      return <td key={colIndex}>{this.renderBadge(row[key])}</td>;
    } else if (key == "payment_status") {
      return (
        <td key={colIndex}>
          {row[key] == "Ön Ödeme Alındı" ? (
            <span className="badge badge-light-warning">Ön Ödeme Alındı</span>
          ) : row[key] == "Ödendi" ? (
            <span className="badge badge-light-success">Ödendi</span>
          ) : (
            <span className="badge badge-light-danger">Ödenmedi</span>
          )}
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default ReservationsTable;
