import Table from "../../../utils/ResultViewer/Table";

class PaymentsTable extends Table {
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key == "is_success") {
      return (
        <td key={colIndex}>
          {row[key] == "success" ? (
            <span className="badge badge-light-success">Başarılı</span>
          ) : (
            <span className="badge badge-light-danger">Başarısız</span>
          )}
        </td>
      );
    } else if (key == "status") {
      return (
        <td key={colIndex}>
          {row[key] == "Onaylandı" ? (
            <span className="badge badge-light-success">Onaylandı</span>
          ) : (
            <span className="badge badge-light-danger">Onaylanmadı</span>
          )}
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default PaymentsTable;
