import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import { ApexOptions } from "apexcharts";
import axios from "axios";

const DailyReservationsChart = () => {
  const [chartData, setChartData] = React.useState<any>([]);

  const fetchChartData = async () => {
    try {
      const response = await axios.get("/dashboard/reservationsCountChart");
      setChartData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const apexOpts: ApexOptions = {
    chart: {
      height: 378,
      type: "line",
      offsetY: 10,
    },
    stroke: {
      width: 5,
    },
    colors: ["#4a81d4"],
    dataLabels: {
      enabled: true,
    },
    labels: chartData?.labels ?? [],
    xaxis: {
      type: "datetime",
    },
    legend: {
      offsetY: 7,
    },
    grid: {
      padding: {
        bottom: 20,
      },
    },
    yaxis: {
      title: {
        text: "Rezervasyon Sayısı",
        style: {
          fontSize: "15px",
        },
      },
    },
  };

  const series = [
    {
      name: "Rezervasyon Sayısı",
      type: "line",
      data: chartData?.data ?? [],
    },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <h4 className="header-title mb-3">Rezervasyonlar</h4>

          <div dir="ltr">
            <Chart
              options={apexOpts}
              series={series}
              type="line"
              height={300}
              className="apex-charts mt-4"
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DailyReservationsChart;
