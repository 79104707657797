import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PaymentMethodsList } from "./PaymentMethodsList";
import PageTitle from "../../../../utils/PageTitle";

const breadCrumbItems = [
  {
    label: "Ödeme Yöntemleri",
    path: "/payment_methods/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const PaymentMethodsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"Ödeme Yöntemleri"}
              />
              <PaymentMethodsList />
            </>
          }
        />
        <Route index element={<Navigate to="/payment_methods/list" />} />
      </Route>
    </Routes>
  );
};

export default PaymentMethodsPage;
