import React, { useState, useEffect } from "react";
import { Modal, Button, FormGroup, Accordion } from "react-bootstrap";
import axios from "axios";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Field from "../../../utils/Form/Widgets/Field";
import Select from "../../../utils/Form/Widgets/Select";

// Props türünü tanımlayalım
type Props = {
  onHide: () => void;
  show: boolean;
  refreshFunction: () => void;
  defaultPropertyValue?: string;
  dateInfo?: { start?: Date; end?: Date };
};

const PricesAddModal: React.FC<Props> = ({
  show,
  onHide,
  refreshFunction,
  defaultPropertyValue,
  dateInfo,
}) => {
  const formatDate = (date: Date | undefined) => {
    if (!date) return null;
    const utcDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return utcDate.toISOString().split("T")[0];
  };

  const [firstDateData, setFirstDateData] = useState({} as any);
  const [formData, setFormData] = useState({
    property: defaultPropertyValue ?? "",
    start_date: formatDate(dateInfo?.start),
    end_date: formatDate(dateInfo?.end ?? dateInfo?.start),
    price: firstDateData.price ?? "",
    discounted_price: firstDateData.discounted_price ?? "",
    min_duration_date: firstDateData.min_duration_date ?? "",
    cleaning_service_price: firstDateData.cleaning_service_price ?? "",
    partial_paid_rate: firstDateData.partial_paid_rate ?? "",
    status: firstDateData.status ?? "",
  });
  const fetchFirstDateData = async () => {
    try {
      const response = await axios
        .get(
          "/prices/getPriceDetail?property=" +
            defaultPropertyValue +
            "&date=" +
            formatDate(dateInfo?.start)
        )
        .then((response) => {
          setFormData({
            property: defaultPropertyValue ?? "",
            start_date: formatDate(dateInfo?.start),
            end_date: formatDate(dateInfo?.end ?? dateInfo?.start),
            price: response.data.data.price ?? "",
            discounted_price: response.data.data.discounted_price ?? "",
            min_duration_date: response.data.data.min_duration_date ?? "",
            cleaning_service_price:
              response.data.data.cleaning_service_price ?? "",
            partial_paid_rate: response.data.data.partial_paid_rate ?? "",
            status: response.data.data.status ?? "",
          });
        });
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    fetchFirstDateData();
  }, [dateInfo, defaultPropertyValue]);
  const [formBuildId, setFormBuildId] = useState("");
  const [formId, setFormId] = useState("");
  const [formToken, setFormToken] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    // Form tokenlarını almak için API çağrısı
    const fetchFormTokens = async () => {
      try {
        const response = await axios.get("/prices/save/add");
        setFormBuildId(response.data.data.form_build_id);
        setFormId(response.data.data.form_id);
        setFormToken(response.data.data.form_token);
        setToken(response.data.data.token);
      } catch (error) {
        console.error("Form tokenlarını alırken hata oluştu:", error);
      }
    };

    fetchFormTokens();
  }, []);

  const pricesStatus = [
    { value: "available", label: "Müsait" },
    { value: "not_available_other", label: "Müsait Değil" },
    { value: "option_other", label: "Opsiyonlu" },
    { value: "no_status", label: "Pasif" },
  ];

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      // Form verilerini gönderme işlemi
      const postData = {
        prices: { ...formData },
        form_id: formId,
        form_build_id: formBuildId,
        form_token: formToken,
      };
      console.log("postData", postData);
      const response = await axios.post("/prices/save/add", postData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Form başarıyla gönderildi:", response.data); // Başarılı isteği loglayalım

      refreshFunction();

      onHide();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios hatası:", error.response?.data || error.message);
        alert(
          "Fiyat detaylarını kaydederken hata oluştu: " +
            (error.response?.data?.message || error.message)
        );
      } else {
        console.error("Bilinmeyen hata:", error);
        alert("Fiyat detaylarını kaydederken bilinmeyen bir hata oluştu.");
      }
    }
  };
  const handleOptionStatusChange = async (status: string) => {
    console.log("handleOptionStatusChange çalıştı.");
    try {
      // Form verilerini gönderme işlemi
      const postData = {
        prices: { ...formData, status },
        form_id: formId,
        form_build_id: formBuildId,
        form_token: formToken,
      };
      console.log("postData", postData);
      const response = await axios.post(
        "/prices/save/status_change",
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Form başarıyla gönderildi:", response.data); // Başarılı isteği loglayalım

      refreshFunction();

      onHide();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios hatası:", error.response?.data || error.message);
        alert(
          "Fiyat detaylarını kaydederken hata oluştu: " +
            (error.response?.data?.message || error.message)
        );
      } else {
        console.error("Bilinmeyen hata:", error);
        alert("Fiyat detaylarını kaydederken bilinmeyen bir hata oluştu.");
      }
    }
  };
  console.log("formData", formData);
  return (
    <Modal
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered"
      size="lg"
      show={show}
      backdrop={true}
      onHide={onHide}
    >
      <form>
        <Modal.Header closeButton>
          <Modal.Title>Fiyatlandırma Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <AutocompleteSelect
              title="Mülk"
              name="property"
              onChange={handleInputChange}
              defaultValue={formData.property}
              multiple={false}
              autoCompleteTokenUrl="/properties/autocomplete"
            />

            <Field
              title="Giriş Tarihi"
              className="form-control"
              type="date"
              name="start_date"
              onChange={handleInputChange}
              value={formData.start_date != null ? formData.start_date : ""}
            />
            <Field
              title="Çıkış Tarihi"
              className="form-control"
              type="date"
              name="end_date"
              onChange={handleInputChange}
              value={formData.end_date != null ? formData.end_date : ""}
            />
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: "15px", marginBottom: "15px" }}
            >
              <Button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => handleOptionStatusChange("available")}
              >
                <span className="fas fa-check"></span> Müsait Duruma Çek
              </Button>
              <Button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => handleOptionStatusChange("not_available_other")}
              >
                <span className="fas fa-check"></span> Müsait Değil Duruma Çek
              </Button>
              <Button
                type="button"
                className="btn btn-warning btn-sm"
                onClick={() => handleOptionStatusChange("option_other")}
              >
                <span className="fas fa-calendar-check"></span> Opsiyonlu Duruma
                Çek
              </Button>
              <Button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => handleOptionStatusChange("no_status")}
              >
                <span className="fas fa-calendar-check"></span> Pasif Duruma Çek
              </Button>
            </div>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header style={{ margin: "0px" }}>
                  Fiyat Düzenle
                </Accordion.Header>
                <Accordion.Body>
                  <Field
                    title="Fiyat"
                    className="form-control"
                    type="text"
                    name="price"
                    onChange={handleInputChange}
                    value={formData.price}
                  />

                  <Field
                    title="İndirimli Fiyat"
                    className="form-control"
                    type="text"
                    name="discounted_price"
                    onChange={handleInputChange}
                    value={formData.discounted_price}
                  />

                  <Field
                    title="Minimum Konaklama Gün Sayısı"
                    className="form-control"
                    type="text"
                    name="min_duration_date"
                    onChange={handleInputChange}
                    value={formData.min_duration_date}
                  />

                  <Field
                    title="Temizlik Ücreti"
                    className="form-control"
                    type="text"
                    name="cleaning_service_price"
                    onChange={handleInputChange}
                    value={formData.cleaning_service_price}
                  />
                  <Field
                    title="Ön Ödeme Oranı (%)"
                    className="form-control"
                    type="text"
                    name="partial_paid_rate"
                    onChange={handleInputChange}
                    value={formData.partial_paid_rate}
                  />
                  <Select
                    title="Fiyatlandırma Durumu"
                    name="status"
                    defaultValue={formData.status}
                    options={pricesStatus}
                    onChange={handleInputChange}
                  />
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: "10px" }}
                  >
                    <Button
                      type="button"
                      className="btn btn-info btn-sm"
                      onClick={handleSubmit}
                    >
                      <span className="fa fa-save"></span> Kaydet
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
                  
            </Accordion>
          </FormGroup>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default PricesAddModal;
