import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Select from "../../../utils/Form/Widgets/Select";
import { DateRangeInput } from "../../../utils/Form/Widgets/DateRangeInput";
import moment from "moment";
type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Prices {
  property?: number;
  start_date?: string;
  end_date?: string;
  price?: string;
  discounted_price?: string;
  min_duration_date?: string;
  cleaning_service_price?: string;
  partial_paid_rate?: string;
  status?: string;
}
class PricesEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Prices() };
  }

  pricesStatus = [
    { value: "available", label: "Müsait" },
    { value: "not_available_vilbox", label: "Müsait Değil-Vilbox" },
    { value: "not_available_other", label: "Müsait Değil-Other" },
    { value: "option", label: "Opsiyonlu" },
    { value: "no_status", label: "Pasif" },
  ];

  getApiUrl(): string {
    return "/prices/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/prices/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      prices: this.state.entity,
    };
  }
  handleRangeChange = (event: any) => {
    const dates = event.target.value.split("to");
    console.log(dates);
    this.setState((state: any) => {
      state.entity.start_date = dates[0].trim();
      state.entity.end_date = dates[1].trim();
    });
    console.log(this.state);
  };
  render(): ReactNode {
    var state = this.state as any;
    let defaultPropertyValue = state.entity.property || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultPropertyValue = 0;
    } else {
      defaultPropertyValue = state.entity.property;
    }

    const isoStartDate = state.entity.start_date
      ? moment(state.entity.start_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    const isoEndDate = state.entity.end_date
      ? moment(state.entity.end_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    console.log("isoStartDate", isoStartDate);

    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Fiyatlandırma Ekle"
                : "Fiyatlandırmayı Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title="Mülk"
                name="property"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={defaultPropertyValue}
                multiple={false}
                autoCompleteTokenUrl="/properties/autocomplete"
              />

              <DateRangeInput
                title="Tarih Aralığı"
                name={"date_range"}
                seperator={"to"}
                onChange={this.handleRangeChange}
                defaultValue={isoStartDate + "to" + isoEndDate}
              />

              <Field
                title="Fiyat"
                className="form-control"
                type="text"
                name="price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.price}
              />

              <Field
                title="İndirimli Fiyat"
                className="form-control"
                type="text"
                name="discounted_price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.discounted_price}
              />
              <Field
                title="Minimum Konaklama Gün Sayısı"
                className="form-control"
                type="text"
                name="min_duration_date"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.min_duration_date}
              />

              <Field
                title="Temizlik Ücreti"
                className="form-control"
                type="text"
                name="cleaning_service_price"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.cleaning_service_price}
              />
              <Field
                title="Ön Ödeme Oranı (%)"
                className="form-control"
                type="text"
                name="partial_paid_rate"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.partial_paid_rate}
              />
              <Select
                title="Fiyatlandırma Durumu"
                name="status"
                defaultValue={state.entity.status}
                options={this.pricesStatus}
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { PricesEditModal };
