import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { RegionsList } from "./components/RegionsList";

const breadCrumbItems = [
  {
    label: "Bölgeler",
    path: "/regions/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const RegionsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Bölge"} />
              <RegionsList />
            </>
          }
        />
        <Route index element={<Navigate to="/regions/list" />} />
      </Route>
    </Routes>
  );
};

export default RegionsPage;
