import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { CategoryList } from "./components/CategoryList";

const breadCrumbItems = [
  {
    label: "Category",
    path: "/categories/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const CategoryPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"Category"}
              />
              <CategoryList />
            </>
          }
        />
        <Route index element={<Navigate to="/categories/list" />} />
      </Route>
    </Routes>
  );
};

export default CategoryPage;
