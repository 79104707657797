import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { ReservationsList } from "./components/ReservationsList";
import ReservationFlow from "./components/reservations_flow/ReservationFlow";

const breadCrumbItems = [
  {
    label: "Rezervasyonlar",
    path: "/reservations/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const ReservationsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={[
                  {
                    label: "Rezervasyonlar",
                    path: "/reservations/list",
                    active: true,
                  },
                ]}
                title={"Rezervasyonlar"}
              />
              <ReservationsList />
            </>
          }
        />
        <Route
          path="flow"
          element={
            <>
              <PageTitle
                breadCrumbItems={[
                  {
                    label: "Rezervasyonlar",
                    path: "/reservations/list",
                    active: false,
                  },
                  {
                    label: "Rezervasyon Akışı",
                    path: "/reservations/flow",
                    active: true,
                  },
                ]}
                title={"Rezervasyon Akışı"}
              />
              <ReservationFlow />
            </>
          }
        />
        <Route index element={<Navigate to="/reservations/list" />} />
      </Route>
    </Routes>
  );
};

export default ReservationsPage;
