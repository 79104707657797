import { lazy, FC, Suspense, useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { meApi } from "../modules/auth/core/_requests";
import { User } from "../modules/auth/core/_models";
import VerticalLayout from "../../layouts/Vertical";
import { Provider } from "react-redux";
import { configureStore } from "../../redux/store";
import PropertiesPage from "../modules/properties/PropertiesPage";
import CategoryPage from "../modules/category/CategoryPage";
import ReservationsPage from "../modules/reservations/ReservationsPage";
import FeaturesPage from "../modules/features/FeaturesPage";
import CustomersPage from "../modules/customers/CustomersPage";
import Dashboard from "../modules/dashboard/Dashboard";
import UsersPage from "../modules/users/UsersPage";
import PricesPage from "../modules/prices/PricesPage";
import RegionsPage from "../modules/regions/RegionsPage";
import PaymentsPage from "../modules/payments/PaymentsPage";
import RelationsPage from "../modules/properties/components/relations/RelationsPage";
import PaymentMethodsPage from "../modules/payments/components/payment_methods/PaymentMethodsPage";
import ImageGrid from "../modules/properties/components/images/ImageGrid";
import PoolsPage from "../modules/pools/PoolsPage";
import { PropertyPoolsList } from "../modules/properties/components/pools/PropertyPoolsList";
import ContactPage from "../modules/contact/ContactPage";
import WatchdogPage from "../modules/watchdog/WatchdogPage";

const PrivateRoutes = () => {
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem("@auth/token") ?? undefined;
  });

  useEffect(() => {
    const fetchUser = async () => {
      const user = await meApi(token);

      if (!user) {
        setToken(undefined);
        return;
      }
      setUser(user.data);
    };
    fetchUser();
  }, [token]);

  let hasAdminRole = false;
  user?.roles.map((role) => {
    if (role == "1") {
      hasAdminRole = true;
    }
  });

  if (!hasAdminRole) {
    return (
      <Provider store={configureStore({})}>
        <Routes>
          <Route element={<VerticalLayout />}>
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </Provider>
    );
  } else {
    return (
      <Provider store={configureStore({})}>
        <Routes>
          <Route element={<VerticalLayout />}>
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="properties/*" element={<PropertiesPage />} />
            <Route path="categories/*" element={<CategoryPage />} />
            <Route path="features/*" element={<FeaturesPage />} />
            <Route path="reservations/*" element={<ReservationsPage />} />
            <Route path="prices/*" element={<PricesPage />} />
            <Route path="customers/*" element={<CustomersPage />} />
            <Route path="users/*" element={<UsersPage />} />
            <Route path="regions/*" element={<RegionsPage />} />
            <Route path="payments/*" element={<PaymentsPage />} />
            <Route path="payment_methods/*" element={<PaymentMethodsPage />} />
            <Route path="property_relations/*" element={<RelationsPage />} />
            <Route path="pools/*" element={<PoolsPage />} />
            <Route
              path="property_images/order/:property_id"
              element={<ImageGrid />}
            />
            <Route
              path="property_pools/list/:property_id"
              element={<PropertyPoolsList />}
            />
            <Route path="contact/*" element={<ContactPage />} />
            <Route path="watchdog/*" element={<WatchdogPage />} />
          </Route>
        </Routes>
      </Provider>
    );
  }
};

export { PrivateRoutes };
