import { ChangeEvent, ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../../utils/Form/FormHandler";
import Field from "../../../../utils/Form/Widgets/Field";
import AutocompleteSelect from "../../../../utils/Form/Widgets/AutocompleteSelect";
import { getPropertyOwnerPhone } from "../../core/_requests";
import Swal from "sweetalert2";
import Textarea from "../../../../utils/Form/Widgets/Textarea";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Relations {
  application_number?: string;
  property?: string;
  real_name?: string;
  reference_platform?: string;
  reference_url?: string;
  owner_phone?: string;
  note?: string;
}
class RelationEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Relations() };
  }

  getApiUrl(): string {
    return "/property_relations/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/property_relations/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      property_relations: this.state.entity,
    };
  }

  async handlePropertyChange(event: any) {
    const propertyId = event.target.value;
    this.handleInputChange(event);
    try {
      const response = await getPropertyOwnerPhone(propertyId);
      if (response) {
        this.setState((prevState: any) => ({
          entity: {
            ...prevState.entity,
            owner_phone: response,
          },
        }));
        Swal.fire({
          title: "Telefon Numarası Bulundu",
          text: "Mülk sahibinin telefon numarası başarıyla alındı.",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      } else {
        this.setState((prevState: any) => ({
          entity: {
            ...prevState.entity,
            owner_phone: "",
          },
        }));
        Swal.fire({
          title: "Telefon Numarası Bulunamadı",
          text: "Bu mülk için telefon numarası bulunamadı.",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.error("Error fetching property owner phone:", error);
      this.setState((prevState: any) => ({
        entity: {
          ...prevState.entity,
          owner_phone: "",
        },
      }));
      Swal.fire({
        title: "Hata",
        text: "Mülk sahibinin telefon numarasını alırken bir hata oluştu. Bu mülkün sahip bilgilerini kontrol ediniz.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  }
  render(): ReactNode {
    var state = this.state as any;
    let defaultProperty = state.entity.property || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultProperty = 0;
    } else {
      defaultProperty = state.entity.property;
    }
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Mülk Bağlantısı Ekle"
                : "Mülk Bağlantısı Düzenle"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                title="Mülk"
                name="property"
                onChange={(event: any) => {
                  this.handlePropertyChange(event);
                }}
                defaultValue={defaultProperty}
                multiple={false}
                autoCompleteTokenUrl="/properties/autocomplete"
                isDisabled={this.props.itemIdForUpdate !== "add" ? true : false}
                required={true}
              />
              <Field
                title="Başvuru Numarası"
                className="form-control"
                type="text"
                name="application_number"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.application_number}
              />
              <Field
                title="Mülk Gerçek İsmi"
                className="form-control"
                type="text"
                name="real_name"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.real_name}
                required={true}
              />
              <Field
                title="Bulunduğu Platform"
                className="form-control"
                type="text"
                name="reference_platform"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.reference_platform}
                required={true}
              />
              <Field
                title="Bulunduğu Platformdaki URL"
                className="form-control"
                type="text"
                name="reference_url"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.reference_url}
                required={true}
              />
              <Field
                title="Mülk Sahibi Telefon"
                className="form-control"
                type="text"
                name="owner_phone"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.owner_phone}
                required={true}
              />
              <Textarea
                title="Not"
                className="form-control"
                type="text"
                name="note"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.note}
                rows={3}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { RelationEditModal };
