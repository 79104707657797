import { ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import Check from "../../../utils/Form/Widgets/Check";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";

type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Payments {
  reservation?: string;
  invoice_name?: string;
  invoice_address?: string;
  invoice_phone?: string;
  invoice_type?: string;
  invoice_nid_or_tax_id?: string;
  amount?: string;
  type?: string;
  transaction_ref?: string;
  is_success?: string;
  intact_synched?: string;
  response?: string;
  payment_description?: string;
  approval_user?: string;
  approval_payment_note?: string;
  status?: string;
}

type State = {
  entity: Payments;
  paymentType: string;
};

class PaymentsEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Payments() };
  }

  getApiUrl(): string {
    return "/payments/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/payments/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      payments: this.state.entity,
    };
  }
  render(): ReactNode {
    var state = this.state as any;
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add" ? "Add" : "Edit"} Ödeme
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <AutocompleteSelect
                onChange={(event: any) => {
                  console.log(event);
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.reservation || 0}
                multiple={false}
                autoCompleteTokenUrl="/reservations/autocomplete"
                className=""
                name="reservation"
                title="Rezervasyon Ref Key"
              />
              <AutocompleteSelect
                title="Ödeme Yöntemi"
                name="type"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.type || 0}
                multiple={false}
                autoCompleteTokenUrl="/payment_methods/autocomplete"
              />
              <Field
                title="Fatura Adı"
                className="form-control"
                type="text"
                name="invoice_name"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.invoice_name}
                required={true}
              />
              <Field
                title="Fatura Adresi"
                className="form-control"
                type="text"
                name="invoice_address"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.invoice_address}
                required={true}
              />
              <Field
                title="Fatura Telefon"
                className="form-control"
                type="text"
                name="invoice_phone"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.invoice_phone}
                required={true}
              />
              <Field
                title="Fatura Tipi"
                className="form-control"
                type="text"
                name="invoice_type"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.invoice_type}
                required={true}
              />
              <Field
                title="Fatura NID veya Vergi No"
                className="form-control"
                type="text"
                name="invoice_nid_or_tax_id"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.invoice_nid_or_tax_id}
                required={true}
              />
              <Field
                title="Ödeme Miktarı"
                className="form-control"
                type="text"
                name="amount"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.amount}
                required={true}
              />

              <Field
                title="Transaction Ref"
                className="form-control"
                type="text"
                name="transaction_ref"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.transaction_ref}
                required={true}
              />

              <Field
                title="Intact Synched"
                className="form-control"
                type="text"
                name="intact_synched"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.intact_synched}
                required={true}
              />
              <Field
                title="Response"
                className="form-control"
                type="text"
                name="response"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.response}
                required={true}
              />
              <Check
                title="Ödeme Başarı Durumu"
                name="is_success"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                type="switch"
                checked={state.entity.is_success == "1" ? true : false}
                options={[""]}
                required={true}
              />
              <Field
                title="Ödeme Açıklaması"
                className="form-control"
                type="text"
                name="payment_description"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.payment_description}
                required={true}
              />
              <Field
                title="Ödeme Onay Notu"
                className="form-control"
                type="text"
                name="approval_payment_note"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.approval_payment_note}
                required={true}
              />
              <Check
                title="Ödeme Durumu"
                name="status"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                type="switch"
                checked={state.entity.status ? true : false}
                options={[""]}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Delete
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { PaymentsEditModal };
