import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import StatisticsWidget from "../../../../components/StatisticsWidget";
import { useTranslation } from "react-i18next";
import { getSummaryBoxData } from "../core/_requests";
import { SummaryBoxData } from "../core/_models";

function SummaryCards() {
  const { t } = useTranslation();
  const [summaryData, setSummaryData] = useState<SummaryBoxData>({
    reservations: {
      null: 0,
      pending: 0,
      confirmed: 0,
      guestCancelled: 0,
      vilboxCancelled: 0,
      checkedIn: 0,
      checkedOut: 0,
    },
    properties: { propertyCount: 0},
    activeProperties: { activePropertyCount: 0},
    customers: { customerCount: 0 },
  });

  const fetchData = async () => {
    const response = await getSummaryBoxData();
    const { reservations, properties, customers, activeProperties } = response.data;

    const updatedSummaryData = {
      reservations: {
        null:
          reservations.find((r: any) => r.reservation_status === null)?.count ||
          0,
        pending:
          reservations.find((r: any) => r.reservation_status === "pending")
            ?.count || 0,
        confirmed:
          reservations.find((r: any) => r.reservation_status === "confirmed")
            ?.count || 0,
        guestCancelled:
          reservations.find(
            (r: any) => r.reservation_status === "guest_cancelled"
          )?.count || 0,
        vilboxCancelled:
          reservations.find(
            (r: any) => r.reservation_status === "vilbox_cancelled"
          )?.count || 0,
        checkedIn:
          reservations.find((r: any) => r.reservation_status === "checked_in")
            ?.count || 0,
        checkedOut:
          reservations.find((r: any) => r.reservation_status === "checked_out")
            ?.count || 0,
      },
      properties: {
        propertyCount: properties[0]?.property_count || 0,
      },
      activeProperties: {
        activePropertyCount: activeProperties[0]?.active_property_count || 0,
      },
      customers: { customerCount: customers[0]?.customer_count || 0 },
    };

    setSummaryData(updatedSummaryData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <h4 className="page-title"> Kullanıcı ve Mülk Bilgileri</h4>
      <div dir="ltr">
        <Row>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="primary"
              description="Toplam Rezervasyon"
              stats={(
                Number(summaryData.reservations.null) +
                Number(summaryData.reservations.vilboxCancelled) +
                Number(summaryData.reservations.pending) +
                Number(summaryData.reservations.guestCancelled) +
                Number(summaryData.reservations.confirmed) +
                Number(summaryData.reservations.checkedOut) +
                Number(summaryData.reservations.checkedIn)
              ).toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="primary"
              description={t("Total Property Count")}
              stats={summaryData.properties.propertyCount.toString()}
              icon="fe-home"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="success"
              description={t("Active Property Count")}
              stats={summaryData.activeProperties.activePropertyCount.toString()}
              icon="fe-home"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="primary"
              description={t("Customer Count")}
              stats={summaryData.customers.customerCount.toString()}
              icon="fe-users"
            />
          </Col>
        </Row>
      </div>
      <h4 className="page-title">Rezervasyon Özeti</h4>
      <div dir="ltr">
        <Row>
          <Col md={6} xl={2}>
            <StatisticsWidget
              variant="warning"
              description="Bekleyenler"
              stats={summaryData.reservations.pending.toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={6} xl={2}>
            <StatisticsWidget
              variant="success"
              description="Onaylandı"
              stats={summaryData.reservations.confirmed.toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={6} xl={2}>
            <StatisticsWidget
              variant="info"
              description="Check-in"
              stats={summaryData.reservations.checkedIn.toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={6} xl={2}>
            <StatisticsWidget
              variant="success"
              description="Check-out"
              stats={summaryData.reservations.checkedOut.toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={12} xl={2}>
            <StatisticsWidget
              variant="danger"
              description="Hatalılar"
              stats={summaryData.reservations.null.toString()}
              icon="fe-calendar"
            />
          </Col>
          <Col md={12} xl={2}>
            <StatisticsWidget
              variant="danger"
              description="İptaller"
              stats={(
                summaryData.reservations.guestCancelled +
                summaryData.reservations.vilboxCancelled
              ).toString()}
              icon="fe-calendar"
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SummaryCards;
