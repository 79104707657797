import axios from "axios";

export async function getSummaryBoxData() {
  try {
    const response = await axios.get("/dashboard/summaryBox");
    return response.data;
  } catch (error) {
    console.error("Error fetching summary box data:", error);
    throw error;
  }
}
