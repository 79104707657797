import { ChangeEvent, ReactNode } from "react";
import { FormGroup, Modal } from "react-bootstrap";
import FormHandler from "../../../utils/Form/FormHandler";
import Field from "../../../utils/Form/Widgets/Field";
import Textarea from "../../../utils/Form/Widgets/Textarea";
import AutocompleteSelect from "../../../utils/Form/Widgets/AutocompleteSelect";
import Select from "../../../utils/Form/Widgets/Select";
import { DateRangeInput } from "../../../utils/Form/Widgets/DateRangeInput";
import moment from "moment";
type Props = {
  onHide: () => void;
  show: boolean;
  itemIdForUpdate?: string;
  refreshFunction?: Function;
};
class Reservations {
  ref_key?: string;
  customer?: number;
  start_date?: string;
  end_date?: string;
  property?: number;
  guest_count?: number;
  participants?: boolean;
  total_price?: string;
  discount_price?: string;
  payment_taken?: string;
  payment_type?: string;
  payment_status?: string;
  reservation_status?: string;
  approval_user_note?: string;
}
class ReservationsEditModal extends FormHandler {
  constructor(props: Props) {
    super(props);
    this.state = { entity: new Reservations() };
  }

  reservationStatusOptions = [
    {
      value: "pending",
      label: "Beklemede",
    },
    {
      value: "confirmed",
      label: "Onaylandı",
    },
    {
      value: "guest_cancelled",
      label: "Müşteri İptal Etti",
    },
    {
      value: "vilbox_cancelled",
      label: "Vilbox İptal Etti",
    },
    {
      value: "checked_in",
      label: "Giriş Yaptı",
    },
    {
      value: "checked_out",
      label: "Çıkış Yaptı",
    },
  ];
  paymentStatusOptions = [
    {
      value: "not_paid",
      label: "Ödenmedi",
    },
    {
      value: "partial_paid",
      label: "Ön Ödeme Alındı",
    },
    {
      value: "paid",
      label: "Ödendi",
    },
  ];

  getApiUrl(): string {
    return "/reservations/save/" + this.props.itemIdForUpdate;
  }
  getSingleUrl() {
    if (this.props.itemIdForUpdate === "add") {
      return null;
    } else {
      return "/reservations/single/" + this.props.itemIdForUpdate;
    }
  }

  getPostData(): object {
    return {
      reservations: this.state.entity,
    };
  }
  handleRangeChange = (event: any) => {
    const dates = event.target.value.split("to");
    console.log(dates);
    this.setState((state: any) => {
      state.entity.start_date = dates[0];
      state.entity.end_date = dates[1];
    });
    console.log(this.state);
  };
  render(): ReactNode {
    var state = this.state as any;
    let defaultCustomerValue = state.entity.customer || 0;
    let defaultPropertyValue = state.entity.property || 0;
    let defaultPaymentTypeValue = state.entity.payment_type || 0;
    if (this.props.itemIdForUpdate === "add") {
      defaultCustomerValue = 0;
      defaultPropertyValue = 0;
      defaultPaymentTypeValue = 0;
    } else {
      defaultCustomerValue = state.entity.customer;
      defaultPropertyValue = state.entity.property;
      defaultPaymentTypeValue = state.entity.payment_type;
    }
    const isoStartDate = state.entity.start_date
      ? moment(state.entity.start_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    const isoEndDate = state.entity.end_date
      ? moment(state.entity.end_date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    console.log("isoStartDate", isoStartDate);
    return (
      <Modal
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered"
        size="lg"
        show={this.props.show}
        backdrop={true}
        onHide={this.props.onHide}
      >
        <form
          onSubmit={(event) => {
            this.handleSubmit(event).then((response) => {
              if (this.props.refreshFunction) {
                this.props.refreshFunction(response?.data?.data?.entity_id);
              }
              this.props.onHide();
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.itemIdForUpdate === "add"
                ? "Rezervasyon Ekle"
                : "Rezervasyonu Düzenle"}
            </Modal.Title>
            <div style={{ color: "red", marginLeft: "10px" }}>
              ref_key: {state.entity.ref_key}
            </div>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title="Müşteri"
                  name="customer"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultCustomerValue}
                  multiple={false}
                  autoCompleteTokenUrl="/customers/autocomplete"
                  isDisabled={this.props.itemIdForUpdate !== "add"}
                />
              </FormGroup>
              <FormGroup>
                <AutocompleteSelect
                  title="Mülk"
                  name="property"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultPropertyValue}
                  multiple={false}
                  autoCompleteTokenUrl="/properties/autocomplete"
                  isDisabled={this.props.itemIdForUpdate !== "add"}
                />
              </FormGroup>
              <DateRangeInput
                title="Rezervasyon Tarih Aralığı"
                name={"date_range"}
                seperator={"to"}
                onChange={this.handleRangeChange}
                defaultValue={isoStartDate + "to" + isoEndDate}
                readonly={this.props.itemIdForUpdate !== "add"}
              />
              <Field
                title="Misafir Sayısı"
                className="form-control"
                type="text"
                name="guest_count"
                onInput={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.guest_count}
                readOnly={false}
              />
              <Textarea
                title="Misafirler"
                name="participants"
                onChange={(event: any) => {
                  this.handleInputChange(event);
                }}
                defaultValue={state.entity.participants}
              />
            </FormGroup>
            {this.props.itemIdForUpdate !== "add" ? (
              <FormGroup>
                <Field
                  title="Toplam Fiyat*"
                  className="form-control"
                  type="text"
                  name="total_price"
                  onInput={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={state.entity.total_price}
                  readOnly={true}
                />

                <Field
                  title="Alınan Ödeme Miktarı*"
                  className="form-control"
                  type="text"
                  name="payment_taken"
                  onInput={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={state.entity.payment_taken}
                  readOnly={true}
                />
                <Field
                  title="İndirim Miktarı"
                  className="form-control"
                  type="text"
                  name="discount_price"
                  onInput={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={state.entity.discount_price}
                  readOnly={false}
                />
                <AutocompleteSelect
                  title="Ödeme Yöntemi"
                  name="payment_type"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={defaultPaymentTypeValue}
                  multiple={false}
                  autoCompleteTokenUrl="/payment_methods/autocomplete"
                />
                <Select
                  title="Ödeme Durumu"
                  name="payment_status"
                  defaultValue={state.entity.payment_status}
                  options={this.paymentStatusOptions}
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                />
                <Select
                  title="Rezervasyon Durumu"
                  name="reservation_status"
                  defaultValue={state.entity.reservation_status}
                  options={this.reservationStatusOptions}
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  nullElement="Lütfen rezervasyon durumunu seçiniz."
                />

                <Textarea
                  title="Vilbox Notu"
                  name="approval_user_note"
                  onChange={(event: any) => {
                    this.handleInputChange(event);
                  }}
                  defaultValue={state.entity.approval_user_note}
                />
              </FormGroup>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-end">
            {this.props.itemIdForUpdate !== "add" ? (
              <button
                type="button"
                onClick={(event) => {
                  this.handleDelete(event).then((response) => {
                    if (this.props.refreshFunction) {
                      this.props.refreshFunction("");
                    }
                  });
                }}
                className="btn btn-danger btn-sm"
              >
                <span className="fa fa-trash"></span>
                Sil
              </button>
            ) : (
              <></>
            )}
            <button type="submit" className="btn btn-success btn-sm">
              <span className="fa fa-floppy-save"></span>
              Kaydet
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export { ReservationsEditModal };
