import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { PricesList } from "./components/PricesList";
import PricingWrapper from "./components/PricingWrapper";

const breadCrumbItems = [
  {
    label: "Fiyatlandırmalar",
    path: "/prices/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const PricesPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Fiyatlandırmalar"} />
              <PricesList />
            </>
          }
        />
        <Route
          path="planning"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Fiyatlandırma Takvim Görünümü"} />
              <PricingWrapper />
            </>
          }
        />
        <Route index element={<Navigate to="/prices/list" />} />
      </Route>
    </Routes>
  );
};

export default PricesPage;
