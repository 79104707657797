import axios, { AxiosResponse } from "axios";

export function getPricingList(
  property: string,
  startDate: string,
  endDate: string
) {
  const paramsArr = [
    property && `property_id=${property}`,
    startDate && `start_date=${startDate}`,
    endDate && `end_date=${endDate}`,
  ];
  const url = `/prices/getList?${paramsArr.filter(Boolean).join("&")}`;
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data);
}

export function getPriceDetail(property: string, date: string) {
  const paramsArr = [
    property && `property=${property}`,
    date && `date=${date}`,
  ];
  const url = `/prices/getPriceDetail?${paramsArr.filter(Boolean).join("&")}`;
  return axios
    .get(url)
    .then((response: AxiosResponse) => response.data)
    .then((response) => response.data);
}

export const formatDate = (date: any) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
