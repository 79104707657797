import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { CustomersEditModal } from "../../../customers/components/CustomersEditModal";
import { ReservationsEditModal } from "../ReservationsEditModal";

const ReservationFlow = () => {
  const [customerId, setCustomerId] = useState<string>("");
  const [reservationId, setReservationId] = useState<string>("");
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {});

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col>
          <div className="timeline">
            <article className="timeline-item timeline-item-left">
              <div className="timeline-desk">
                <div className="timeline-box">
                  <span className="arrow-alt"></span>
                  <span className="timeline-icon">
                    <i className="mdi mdi-adjust"></i>
                  </span>
                  <h4
                    className="mt-0 font-16"
                    onClick={() => {
                      setCustomerId("add");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Müşteri Oluştur
                  </h4>
                  <p className=" text-muted">
                    <small>
                      Rezervasyon oluşturma akışının ilk adımı müşteri
                      oluşturmaktır.
                    </small>
                  </p>
                </div>
              </div>
            </article>
            {customerId === "add" ? (
              <CustomersEditModal
                show={customerId === "add"}
                itemIdForUpdate={customerId}
                onHide={() => {
                  setCustomerId("");
                }}
                refreshFunction={(itemId: any) => {
                  if (itemId !== undefined) {
                    setCustomerId(itemId);
                  }
                }}
              />
            ) : (
              <></>
            )}

            <article className="timeline-item">
              <div className="timeline-desk">
                <div className="timeline-box">
                  <span className="arrow"></span>
                  <span className="timeline-icon">
                    <i className="mdi mdi-adjust"></i>
                  </span>
                  <h4
                    className="mt-0 font-16"
                    onClick={() => {}}
                    style={{ cursor: "pointer" }}
                  >
                    Mülk Bulma Robotu
                  </h4>
                  <p className=" text-muted">
                    <small>(YaKINDA)</small>
                  </p>
                </div>
              </div>
            </article>

            <article className="timeline-item timeline-item-left">
              <div className="timeline-desk">
                <div className="timeline-box">
                  <span className="arrow-alt"></span>
                  <span className="timeline-icon">
                    <i className="mdi mdi-adjust"></i>
                  </span>
                  <h4
                    className="mt-0 font-16"
                    onClick={() => {}}
                    style={{ cursor: "pointer" }}
                  >
                    Mülklerin Müşteri İle Paylaşılması
                  </h4>
                  <p className="text-muted">
                    <small>(YAKINDA)</small>
                  </p>
                </div>
              </div>
            </article>

            <article className="timeline-item">
              <div className="timeline-desk">
                <div className="timeline-box">
                  <span className="arrow"></span>
                  <span className="timeline-icon">
                    <i className="mdi mdi-adjust"></i>
                  </span>
                  <h4
                    className="mt-0 font-16"
                    onClick={() => {
                      setReservationId("add");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Rezervasyonun Oluşturulması
                  </h4>
                  <p className="text-muted">
                    <small>Not Yazılacak.</small>
                  </p>
                </div>
              </div>
            </article>
            {reservationId === "add" ? (
              <ReservationsEditModal
                show={reservationId === "add"}
                itemIdForUpdate={reservationId}
                onHide={() => {
                  setReservationId("");
                }}
                refreshFunction={(itemId: any) => {
                  if (itemId !== undefined) {
                    setReservationId(itemId);
                  }
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ReservationFlow;
