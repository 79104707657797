import { FC, SyntheticEvent, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Table from "../../../../utils/ResultViewer/Table";
import { PaymentMethodsEditModal } from "./PaymentMethodsEditModal";

const PaymentMethodsList: FC = () => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState("");
  const [refreshFunction, setRefreshFunction] = useState<any>(() => {});

  return (
    <>
      <Table
        title="Ödeme Yöntemleri"
        dataUrl="/payment_methods/list"
        editCallback={(id: any, refresh: Function) => {
          setRefreshFunction(() => refresh);
          setItemIdForUpdate(id);
        }}
        deleteCallback={(id: any, refresh: Function) => {
          axios.get("/payment_methods/save/" + id).then((response) => {
            let csrfData = response.data.data;
            Swal.fire({
              title: "Warning",
              html: "Are you want to delete this payment method?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Delete",
              cancelButtonText: "Cancel",
              customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-primary",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                axios
                  .delete("payment_methods/save/" + id, {
                    data: csrfData,
                  })
                  .then(() => {
                    refresh();
                  });
              }
            });
          });
        }}
        actionItems={[
          {
            text: "Ödeme Yöntemi Ekle",
            class: "btn btn-sm btn-primary",
            icon: "fa fa-plus",
            onClick: function (event: SyntheticEvent, refresh: Function) {
              setItemIdForUpdate("add");
              setRefreshFunction(() => refresh);
            },
          },
        ]}
      />
      {itemIdForUpdate !== "" ? (
        <PaymentMethodsEditModal
          show={itemIdForUpdate !== ""}
          itemIdForUpdate={itemIdForUpdate}
          onHide={() => {
            setItemIdForUpdate("");
          }}
          refreshFunction={(itemId: any) => {
            if (itemId !== undefined) {
              setItemIdForUpdate(itemId);
            }
            refreshFunction();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export { PaymentMethodsList };
