import { ResultProps } from "../../../utils/ResultViewer/ResultViewer";
import Table from "../../../utils/ResultViewer/Table";
import "../Properties.css";
type PropertiesProps = ResultProps & {
  viewCallback: Function;
  imagesCallback: Function;
  poolsCallback: Function;
};
class PropertiesTable extends Table {
  //@ts-ignore
  public state: PropertiesProps;
  constructor(props: PropertiesProps) {
    super(props);
    this.state = {
      title: props.title,
      actionItems: props.actionItems,
      dataUrl: props.dataUrl,
      data: {
        headers: {},
        data: [],
        search: [],
        pagination: {
          limit: 100,
          page: 1,
          total_count: 0,
        },
      },
      page: 1,
      orderBy: "",
      orderDirection: "",
      editCallback: props.editCallback,
      deleteCallback: props.deleteCallback,
      filterParams: {},
      viewCallback: props.viewCallback,
      imagesCallback: props.imagesCallback,
      poolsCallback: props.poolsCallback,
    };
  }
  renderRow(row: any, key: string, colIndex: number): JSX.Element {
    if (key === "edit_actions") {
      return (
        <td key={colIndex}>
          <div className="d-flex justify-content-end flex-shrink-0">
            <button
              onClick={() => this.props.editCallback(row[key], this.fetchData)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fa fa-edit"></span>
            </button>

            <button
              onClick={() => this.state.viewCallback(row[key], this.fetchData)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fas fa-eye"></span>
            </button>
            <button
              onClick={() =>
                this.state.imagesCallback(row[key], this.fetchData)
              }
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fas fa-image"></span>
            </button>
            <button
              onClick={() => this.state.poolsCallback(row[key], this.fetchData)}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            >
              <span className="fas fa-swimming-pool"></span>
            </button>
            <button
              onClick={() =>
                this.props.deleteCallback(row[key], this.fetchData)
              }
              className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
            >
              <span className="fa fa-trash"></span>
            </button>
          </div>
        </td>
      );
    } else if (key == "url_alias") {
      return (
        <td key={colIndex}>
          <a
            href={process.env.REACT_APP_FRONT_URL + "/" + row[key]}
            target="_blank"
            rel="noreferrer"
          >
            {row[key]}
          </a>
        </td>
      );
    } else if (key == "status") {
      console.log("value:",row[key]);
      return (
        <td key={colIndex}>
          {row[key] == "Aktif" ? (
            <span className="badge badge-light-success">Aktif</span>
          ) : (
            <span className="badge badge-light-danger">Pasif</span>
          )}
        </td>
      );
    } else {
      return super.renderRow(row, key, colIndex);
    }
  }
}

export default PropertiesTable;
