import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PropertyRelationsList } from "./PropertyRelationsList";
import PageTitle from "../../../../utils/PageTitle";

const breadCrumbItems = [
  {
    label: "Mülk Bağlantıları",
    path: "/property_relations/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const RelationsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"Mülk Bağlantıları"}
              />
              <PropertyRelationsList />
            </>
          }
        />

        <Route index element={<Navigate to="/property_relations/list" />} />
      </Route>
    </Routes>
  );
};

export default RelationsPage;
