import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { PaymentsList } from "./components/PaymentsList";

const breadCrumbItems = [
  {
    label: "Ödemeler",
    path: "/payments/",
    active: false,
  },
  {
    label: "",
    path: "/",
    active: false,
  },
];

const PaymentsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle breadCrumbItems={breadCrumbItems} title={"Ödemeler"} />
              <PaymentsList />
            </>
          }
        />
        <Route index element={<Navigate to="/payments/list" />} />
      </Route>
    </Routes>
  );
};

export default PaymentsPage;
