import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

interface ReservationsInfoModalProps {
  show: boolean;
  itemIdForModal: string;
  onHide: () => void;
}

const ReservationsInfoModal: FC<ReservationsInfoModalProps> = ({
  show,
  itemIdForModal,
  onHide,
}) => {
  const [reservationInfo, setReservationInfo] = useState<any>(null);
  useEffect(() => {
    if (itemIdForModal) {
      axios
        .get(`/reservations/single/${itemIdForModal}`)
        .then((response) => {
          setReservationInfo(response.data);
        })
        .catch((error) => {
          console.error("Error fetching reservation info:", error);
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Rezervasyon bilgileri getirilirken bir hata oluştu",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  }, [itemIdForModal]);

  let reservationSummaryUrl = "";
  let paymentUrl = "";
  let reservationSummaryShareLinks = { whatsapp: "", mail: "" };
  let paymentShareLinks = { whatsapp: "", mail: "" };

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    Swal.fire({
      icon: "success",
      title: "Link Kopyalandı",
      showConfirmButton: false,
      timer: 1500,
    });
  };
  const createShareLinks = (url: string) => {
    return {
      whatsapp: `https://wa.me/?text=${encodeURIComponent(url)}`,
      instagram: `https://www.instagram.com/?url=${encodeURIComponent(url)}`,
      mail: `mailto:?subject=Reservation%20Details&body=${encodeURIComponent(
        url
      )}`,
    };
  };
  if (!reservationInfo) {
    return null;
  } else {
    reservationSummaryUrl =
      process.env.REACT_APP_FRONT_URL +
      `/reservation/${reservationInfo.data.ref_key}`;
    paymentUrl =
      process.env.REACT_APP_FRONT_URL +
      `/payment/${reservationInfo.data.ref_key}`;
    reservationSummaryShareLinks = createShareLinks(reservationSummaryUrl);
    paymentShareLinks = createShareLinks(paymentUrl);
  }
  return (
    <Modal show={show} onHide={onHide} size="lg" centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Rezervasyon Bilgileri Paylaş</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <span className="fa fa-receipt"></span>
            <span style={{ marginLeft: "5px" }}>Rezervasyon Özeti Linki:</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="me-2">
              <a href={reservationSummaryUrl}>{reservationSummaryUrl}</a>
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => copyToClipboard(reservationSummaryUrl)}
            >
              <span className="fa fa-copy fa-lg"></span>
            </Button>
            <a
              href={reservationSummaryShareLinks.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2"
            >
              <span
                className="fab fa-whatsapp fa-lg"
                style={{ color: "#25D366" }}
              ></span>
            </a>
            <a
              href={reservationSummaryShareLinks.mail}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="fa fa-envelope fa-lg"
                style={{ color: "#000000" }}
              ></span>
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <span className="fa fa-money-bill"></span>
            <span style={{ marginLeft: "5px" }}>Ödeme Linki:</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="me-2">
              {" "}
              <a href={paymentUrl}>{paymentUrl}</a>
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => copyToClipboard(paymentUrl)}
            >
              <span className="fa fa-copy fa-lg"></span>
            </Button>
            <a
              href={paymentShareLinks.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="me-2"
            >
              <span
                className="fab fa-whatsapp fa-lg"
                style={{ color: "#25D366" }}
              ></span>
            </a>
            <a
              href={paymentShareLinks.mail}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                className="fa fa-envelope fa-lg"
                style={{ color: "#000000" }}
              ></span>
            </a>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ReservationsInfoModal };
