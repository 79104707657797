import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import PageTitle from "../../utils/PageTitle";
import { ContactList } from "./ContactList";

const breadCrumbItems = [
  {
    label: "İletişim Formları",
    path: "/contact/list",
    active: false,
  },
];

const ContactPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              <PageTitle
                breadCrumbItems={breadCrumbItems}
                title={"İletişim Form Kayıtları"}
              />
              <ContactList />
            </>
          }
        />
        <Route index element={<Navigate to="/contact/list" />} />
      </Route>
    </Routes>
  );
};

export default ContactPage;
